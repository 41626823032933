import React from "react"
import { graphql } from "gatsby"
import Gallery from "@browniebroke/gatsby-image-gallery"
import "@browniebroke/gatsby-image-gallery/dist/style.css"

import Message from "../../../components/message"
import Images from "../../../components/images"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Section from "../../../components/section"

const VanceInfo = ({ data }) => {
  const generalImages = data?.generalImages?.nodes?.map(
    ({ childImageSharp }) => childImageSharp
  )

  const beforeAfterImages = data?.beforeAfterImages?.nodes?.map(
    ({ childImageSharp }) => childImageSharp?.fluid
  )

  return (
    <Layout>
      <SEO title="Vancé" />

      <Section>
        <article className="prose lg:prose-xl">
          <h1>Vancé</h1>

          <Message>
            Zoals u ziet is er meer dat we kunnen bereiken met het licht van de
            VPL. Door een oplossing te zoeken naar ongewenst haar ontdekten
            professoren dat er meer gebeurde, niet alleen op de huid maar ook in
            en onder de huid was er actie ontdekt! Door de <u>lichtfilters</u>{" "}
            aan te passen konden ze het activiteitencentrum beïnvloeden en de
            volgende behandelingen werden mogelijk.
          </Message>

          <hr />

          <p>
            Hier gaan we te werk met een gele filter en een lagere energie. Door
            te flitsen op de huid gaat de onder de huid liggende adertjes
            reageren door dat ze donkerder zijn dan de huid en nemen ze veel
            meer warmte op waarbij het bloed stolt, waardoor het afgebroken
            wordt door je lichaam. Ook hierbij kan jij helpen de resultaten te
            verbeteren door ervan af te blijven en een afspraak te boeken. Een
            goede voorbereiding en info is dus essentieel, dit wordt u uitvoerig
            uitgelegd bij een <b>inleidend gesprek of intakegesprek</b>.
          </p>

          <h2>Foto's</h2>
          <h3>Algemene foto's</h3>
          <p>
            Deze foto's geven een algemeen beeld van hoe deze behandeling werkt
          </p>

          <Message>
            Klik op de afbeelding om ze te bekijken op volledige grootte
          </Message>

          <div className="mx-4">
            <Gallery images={generalImages} />
          </div>

          <h3>Voor/na foto's</h3>
          <p>
            Krijg een indruk van wat de resultaten zijn van deze behandeling
          </p>
          <Images size="medium" data={beforeAfterImages} />
        </article>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query VanceInfoPageQuery {
    generalImages: allFile(
      filter: { relativeDirectory: { eq: "info/vance/general" } }
      sort: { fields: name, order: ASC }
    ) {
      ...GalleryImages
    }
    beforeAfterImages: allFile(
      filter: { relativeDirectory: { eq: "info/vance/before-after" } }
      sort: { fields: name, order: ASC }
    ) {
      ...RegularImages
    }
  }
`

export default VanceInfo
